
import { defineComponent, ref, nextTick, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { processRouterQueryParams } from '@/utils/';
import { useSearchConfig } from './composables/use-search-config';
import useBrandList from './composables/use-list';
import { getDesignTaskOptions } from '@/modules/task-manage/design-task/api';
// import { cloneDeep } from 'lodash-es';
import type { IGetDesignTaskOptionsRes } from '@/modules/task-manage/design-task/api/types';
import { SUB_STATUS_ENUMS, SORT_TYPE_ENUM } from '@/modules/miaokuan-manage/common/constant';
// import slsLogger from '@/core/plugins/slsLogger/SLSLogger';
// import { EVENT_TYPE_ENUM } from '@/core/plugins/slsLogger/constant';
import { ElMessage, ElForm } from 'element-plus';
import NumberRangeInput from '../../../common/components/number-range-input';
import BrandItem from '@/modules/miaokuan-manage/common/components/brand-item/index.vue';
import CancelSub from '@/modules/miaokuan-manage/common/components/cancel-sub/index.vue';
import { IBrandSubscribeReq } from '@/modules/miaokuan-manage/sub/api/type.d';
import { postBrandSubscribe } from '@/modules/miaokuan-manage/sub/api';
import IconArrowDefault from '@/assets/images/resource/i_arrow_default.svg';
import IconArrowDown from '@/assets/images/resource/i_arrow_down.svg';
import IconArrowTop from '@/assets/images/resource/i_arrow_top.svg';
import { IBrandBrandListPageListItem } from '@/modules/miaokuan-manage/brand/api/type.d';

export default defineComponent({
  components: {
    BrandItem,
    NumberRangeInput,
    CancelSub,
  },
  setup() {
    const route = useRoute();
    const goodsEl = ref<null|HTMLElement>(null);
    const defaultSearchEl = ref<InstanceType<typeof ElForm> | null>(null);

    // 列表查询
    const {
      sortFiltersTags,
      handleRemoveFilterItem,
      handleReset,
      handleCurrentSizeChange,
      defaultSearchKeys,
      params,
      brandList,
      reload,
      listLoading,
      finish,
      listDisabled,
      loadMore,
      isEmpty,
      sortList,
      sortConfig,
      handleChangeSort,
      initPagination,
      currentViewPage,
      pageSize,
      total,
    } = useBrandList();

    // 列表查询
    const handleSearch = async () => {
      try {
        reload();
      } catch (error) {
        console.log(error);
      }
    };

    // 字典和搜索项el
    const {
      searchConfig,
      getOptions,
    } = useSearchConfig(params);
    getOptions();

    // 收录博主相关
    const recordConfig = ref<{visible:boolean;data:any;}>(
      { visible: false, data: [] },
    );
    const openRecordDialog = () => {
      recordConfig.value.visible = true;
    };
    const handleRecordBlogger = () => {

    };

    // 打开取消订阅弹框
    const cancelSubConfig = ref<{data:any;visible:boolean;}>(
      { visible: false, data: {} },
    );
    const openCancelSub = (row:IBrandBrandListPageListItem) => {
      cancelSubConfig.value.visible = true;
      cancelSubConfig.value.data = row;
    };

    // 订阅｜取消订阅
    const handleSubToggle = async (
      isSubscribe:SUB_STATUS_ENUMS = SUB_STATUS_ENUMS.SUB,
      row:IBrandBrandListPageListItem) => {
      if (row) {
        // 品牌
        const param:IBrandSubscribeReq = {
          brandId: row?.brandId,
          isSubscribe,
        };
        await postBrandSubscribe(param);
        if (isSubscribe === SUB_STATUS_ENUMS.CANCEL) {
          cancelSubConfig.value.visible = false;
        }
        row.alreadySubscribe = isSubscribe === SUB_STATUS_ENUMS.SUB;
        const msg = isSubscribe === SUB_STATUS_ENUMS.SUB ? '订阅成功' : '取消订阅成功';
        ElMessage.success(msg);
      }
    };

    // 取消订阅弹窗-确定
    const handleConfirmCancel = (
      isSubscribe:SUB_STATUS_ENUMS = SUB_STATUS_ENUMS.SUB,
      row:IBrandBrandListPageListItem,
    ) => {
      // slsLogger.send({
      //   eventType: EVENT_TYPE_ENUM.CLICK,
      //   eventDetails: {
      //     eventName: '取消订阅弹窗-确定',
      //     targetId: row.brandId,
      //   },
      // });
      handleSubToggle(isSubscribe, row);
    };

    // 选图-设计任务
    const designTaskOptions = ref<IGetDesignTaskOptionsRes[]>([]);
    const getDesignOptions = async () => {
      try {
        const { data } = await getDesignTaskOptions();
        designTaskOptions.value = data;
      } catch (error) {
        console.log(error);
      }
    };
    getDesignOptions();

    const init = async () => {
      await nextTick();
      // url 获取请求参数
      const urlParams = processRouterQueryParams(params.value);
      urlParams.pageNum = 0;
      initPagination(goodsEl.value!);
      params.value = urlParams;
    };

    onMounted(() => {
      init();
    });

    return {
      sortFiltersTags,
      handleRemoveFilterItem,
      handleReset,
      handleCurrentSizeChange,
      total,
      currentViewPage,
      pageSize,
      goodsEl,
      defaultSearchEl,
      defaultSearchKeys,
      handleSearch,
      handleChangeSort,
      sortList,
      sortConfig,
      searchConfig,
      designTaskOptions,
      brandList,
      listLoading,
      finish,
      listDisabled,
      loadMore,
      isEmpty,
      params,
      cancelSubConfig,
      handleSubToggle,
      openCancelSub,
      recordConfig,
      openRecordDialog,
      handleRecordBlogger,
      useListSearch: useSearchConfig,
      handleConfirmCancel,
    };
  },
  render() {
    return (
      <div>
        <main class="container">
          <filter-bar
            tags={this.sortFiltersTags}
            searchConfig={this.searchConfig}
            onReset={this.handleReset}
            onRemoveItem={this.handleRemoveFilterItem}
          />
          <div class="sorting-content">
            {this.sortList.map(item => (
              <div
                key={item.value}
                class="item"
                onClick={() => this.handleChangeSort(item.value)}
              >
                <div>{item.label}</div>
                <div class="arrow">
                  <img src={IconArrowDefault} class="arrow_icon" />
                  {this.sortConfig.prop === item.value
                  && this.sortConfig.order === SORT_TYPE_ENUM.TOP
                  && <img src={IconArrowTop} class="arrow_icon" />
                  }
                  {this.sortConfig.prop === item.value
                  && this.sortConfig.order === SORT_TYPE_ENUM.DOWN
                  && <img src={IconArrowDown} class="arrow_icon" />
                  }
                </div>
              </div>
            ))}
          </div>
          <section>
            <div
              class="goods-container"
              ref="goodsEl"
              v-infinite-scroll={this.loadMore}
              infinite-scroll-distance={50}
              infinite-scroll-disabled={this.listDisabled}
              infinite-scroll-immediate={false}
            >
              {this.brandList.map((v, i) => (
                <brand-item
                  key={i}
                  data={v}
                  onSubToggle={this.handleSubToggle}
                  onOpenCancel={this.openCancelSub}
                />
              ))}
            </div>
            {this.isEmpty ? (
              <empty />
            ) : (
              <div class="load-other">
                {this.listLoading && (
                  <p><i class="el-icon-loading"></i> 加载中...</p>
                )}
                {this.finish && (
                  <p>已经到底了</p>
                )}
              </div>
            )}
            <list-pagination
              total={this.total}
              current={this.currentViewPage}
              pageSize={this.pageSize}
              onCurrentChange={this.handleCurrentSizeChange}
            />
          </section>
        </main>
        <cancel-sub
          v-model={[this.cancelSubConfig.visible, 'visible']}
          onSubCancel={() => {
            this.handleConfirmCancel(SUB_STATUS_ENUMS.CANCEL, this.cancelSubConfig.data);
          }
          }
        />
      </div>
    );
  },
});
