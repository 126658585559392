import {
  SEARCH_BASE_SETTING_ENUM,
} from '@/modules/miaokuan-manage/common/constant';
import type{ ISearchConfigItem } from '../type.d';
import { computed, Ref } from 'vue';
import { ICustomParams } from '../type.d';
import { useDictionaryStore } from '../dictionary';
import { MP_DICTIONARY_KEY } from '@/constant/dictionary';

export const useSearchConfig = (
  params: Ref<ICustomParams>,
) => {
  const dictionaryStore = useDictionaryStore();
  // 品牌类型
  const BRAND_TYPE_OPTIONS = computed(() => dictionaryStore
    .cxDictionaryMap[MP_DICTIONARY_KEY.MOKEN_BRAND_TYPE] || []);
  // 地区
  const BRAND_AREA_OPTIONS = computed(() => dictionaryStore
    .cxDictionaryMap[MP_DICTIONARY_KEY.MOKEN_BRAND_AREA] || []);
  // 品牌名称
  const BRAND_NAME_OPTIONS = computed(() => dictionaryStore
    .cxDictionaryMap[MP_DICTIONARY_KEY.MOKEN_BRAND_NAME] || []);
  const searchConfig = computed(() => {
    const baseConfig: ISearchConfigItem[] = [
      {
        key: SEARCH_BASE_SETTING_ENUM.BRAND_TYPE,
        render: () => {
          return (
            <virtual-select
              label="品牌类型"
              v-model={params.value.brandType}
              options={BRAND_TYPE_OPTIONS.value}
            />
          );
        },
      },
      {
        key: SEARCH_BASE_SETTING_ENUM.BRAND_AREA,
        render: () => {
          return (
            <virtual-select
              label="地区"
              v-model={params.value.brandArea}
              options={BRAND_AREA_OPTIONS.value}
            />
          );
        },
      },
      {
        key: SEARCH_BASE_SETTING_ENUM.BRAND_NAME,
        render: () => {
          return (
            <div class="brand-filter-select">
              <el-select
                filterable
                clearable
                placeholder="请选择品牌"
                v-model={params.value.brandName}
              >
                {BRAND_NAME_OPTIONS.value.map(v => (
                  <el-option value={v.label} label={v.label} />
                ))}
              </el-select>
            </div>
          );
        },
      },
    ];
    return baseConfig;
  });
  const getOptions = () => {
    dictionaryStore.getCxDictionary([
      MP_DICTIONARY_KEY.MOKEN_BRAND_TYPE,
      MP_DICTIONARY_KEY.MOKEN_BRAND_AREA,
      MP_DICTIONARY_KEY.MOKEN_BRAND_NAME,
    ]);
  };

  return {
    searchConfig,
    getOptions,
  };
};
