import { ref, computed, nextTick, watch } from 'vue';
import { getBrandList } from '@/modules/miaokuan-manage/brand/api';
import { cloneDeep } from 'lodash-es';
import {
  IGoodsItem,
  ICustomParams,
  // IParams,
  ISortParams,
  ISearchKeys,
  IFilterTagsItem, IParams,
} from '../type.d';
import {
  SORT_TYPE_ENUM,
  SORT_WORD_ENUM,
  SEARCH_BASE_SETTING_ENUM,
  MK_SORT_WORD_LIST,
} from '@/modules/miaokuan-manage/common/constant';
import { usePagination } from './use-pagination';
import { trackingExposure } from '@/utils/tracking';
import { $filters } from '@/core/plugins/filter';
import { MP_DICTIONARY_KEY } from '@/constant/dictionary';
import { useDictionaryStore } from '../dictionary';
/* 支持 列表查询 */

export default () => {
  const {
    handleScrollToPage,
    initPagination,
    pageSize,
    reqPageIndex,
    currentViewPage,
  } = usePagination();
  const dictionaryStore = useDictionaryStore();

  const defaultSearchKeys = ref<Array<ISearchKeys>>([]);
  const sortConfig = ref<ISortParams>({
    prop: SORT_WORD_ENUM.LATELY,
    order: SORT_TYPE_ENUM.DOWN,
  });
  const total = ref(0);
  const baseParams: ICustomParams = {
    brandType: '',
    brandArea: '',
    brandName: '',
  };
  // 品牌类型
  const BRAND_TYPE_OPTIONS = computed(() => dictionaryStore
    .cxDictionaryMap[MP_DICTIONARY_KEY.MOKEN_BRAND_TYPE] || []);
  // 地区
  const BRAND_AREA_OPTIONS = computed(() => dictionaryStore
    .cxDictionaryMap[MP_DICTIONARY_KEY.MOKEN_BRAND_AREA] || []);
  const params = ref<ICustomParams>(cloneDeep(baseParams));
  const searchType = ref<'default'|'advanced'>('default');
  const finish = ref(false);
  const brandList = ref<IGoodsItem[]>([]);
  const formatParams = (row: ICustomParams) => {
    const data:IParams = {};
    return {
      ...row,
      ...data };
  };
  const getList = async (current: number) => {
    try {
      const res = await getBrandList({
        ...formatParams(cloneDeep(params.value)),
        pageNum: current,
        pageSize: pageSize.value,
        ...sortConfig.value,
      });
      const newList = res.data?.list || [];
      brandList.value.push(...newList);
      trackingExposure(newList, 'brandId', {
        eventName: '品牌曝光量',
      });
      total.value = Number(res.data.total);
      if (Number(res.data.total) <= reqPageIndex.value * pageSize.value) {
        finish.value = true;
      } else {
        finish.value = false;
      }
      return Promise.resolve();
    } catch (e) {
      console.log('获取首页列表 error', e);
      return Promise.reject();
    }
  };
  const reload = () => {
    reqPageIndex.value = 1;
    brandList.value = [];
    getList(reqPageIndex.value);
  };
  // 懒加载
  const listLoading = ref<boolean>(false);
  const listDisabled = computed(() => listLoading.value || finish.value);
  const loadMore = async () => {
    listLoading.value = true;
    reqPageIndex.value += 1;
    await getList(reqPageIndex.value);
    listLoading.value = false;
  };
  const handleChangeSort = (val: SORT_WORD_ENUM) => {
    if (val === sortConfig.value.prop) {
      sortConfig.value.order = Object.values(SORT_TYPE_ENUM).find(v => v !== sortConfig.value.order) || '';
    } else {
      sortConfig.value.prop = val;
      sortConfig.value.order = SORT_TYPE_ENUM.DOWN;
    }
    reload();
  };
  const handleCurrentSizeChange = async (val: number) => {
    try {
      if (listLoading.value) return;
      if (val > reqPageIndex.value) {
        listLoading.value = true;
        const reqArr = [];
        while (reqPageIndex.value < val) {
          reqPageIndex.value += 1;
          // eslint-disable-next-line no-await-in-loop
          reqArr.push(getBrandList({
            ...formatParams(cloneDeep(params.value)),
            pageNum: reqPageIndex.value,
            pageSize: pageSize.value,
            ...sortConfig.value,
          }));
        }
        const resArr = await Promise.all(reqArr);
        const list:IGoodsItem[] = [];
        resArr.forEach((v, i) => {
          const { data } = v;
          list.push(...data.list);
          if (i === resArr.length - 1) {
            total.value = Number(data.total);
            if (Number(data.total) <= reqPageIndex.value * pageSize.value) {
              finish.value = true;
            } else {
              finish.value = false;
            }
          }
        });
        brandList.value.push(...list);
        trackingExposure(list, 'brandId', {
          eventName: '品牌曝光量',
        });
        await nextTick();
        handleScrollToPage(val);
      } else {
        handleScrollToPage(val);
        //
      }
    } catch (error) {
      console.log('获取列表数据错误', error);
    }
    listLoading.value = false;
  };
  const isEmpty = computed(() => {
    return !brandList.value.length && !listLoading.value;
  });
  const tagSortList = ref<Set<ISearchKeys>>(new Set([]));
  const filtersTags = computed(() => {
    const tags: IFilterTagsItem[] = [];
    Object.entries(params.value).forEach((v) => {
      const [key] = v;
      const value = v[1] as any;
      switch (key) {
        case SEARCH_BASE_SETTING_ENUM.BRAND_TYPE:
          if (value.length === 0) {
            tagSortList.value.delete(key);
            return;
          }
          tags.push({
            label: '品牌类型',
            key,
            content: $filters.getEnumLabel(BRAND_TYPE_OPTIONS.value, value),
          });
          tagSortList.value.add(key);
          break;
        case SEARCH_BASE_SETTING_ENUM.BRAND_AREA:
          if (value.length === 0) {
            tagSortList.value.delete(key);
            return;
          }
          tags.push({
            label: '地区',
            key,
            content: $filters.getEnumLabel(BRAND_AREA_OPTIONS.value, value),
          });
          tagSortList.value.add(key);
          break;
        case SEARCH_BASE_SETTING_ENUM.BRAND_NAME:
          if (value.length === 0) {
            tagSortList.value.delete(key);
            return;
          }
          tags.push({
            label: '品牌',
            key,
            content: value,
          });
          tagSortList.value.add(key);
          break;
        default:
          break;
      }
    });
    return tags;
  });
  const sortFiltersTags = computed(() => {
    return [...tagSortList.value].map((v) => {
      return filtersTags.value.find(it => it.key === v);
    });
  });
  const handleRemoveFilterItem = (
    key: ISearchKeys,
  ) => {
    switch (key) {
      case SEARCH_BASE_SETTING_ENUM.BRAND_TYPE:
      case SEARCH_BASE_SETTING_ENUM.BRAND_AREA:
      case SEARCH_BASE_SETTING_ENUM.BRAND_NAME:
        params.value[key] = '';
        break;
      default:
        break;
    }
  };
  const handleReset = () => {
    params.value = cloneDeep(baseParams);
  };
  watch(() => filtersTags.value, () => {
    reload();
  });
  return {
    sortFiltersTags,
    handleRemoveFilterItem,
    handleReset,
    handleCurrentSizeChange,
    total,
    initPagination,
    pageSize,
    currentViewPage,
    defaultSearchKeys,
    searchType,
    baseParams,
    handleChangeSort,
    sortConfig,
    sortList: MK_SORT_WORD_LIST,
    params,
    brandList,
    listLoading,
    finish,
    listDisabled,
    isEmpty,
    loadMore,
    reload,
  };
};
